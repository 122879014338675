<template>
	<div class="asrAndVs" id="productId">
		<div class="content">
			<div class="center">
				<div class="asr">
					<div class="asr-title">语音识别</div>
					<div class="asr-center">
						<p class="asr-center-text">语音识别结果将在这里显示</p>
						<img
							v-if="asrLoading"
							v-lazy="loading1Img"
							alt=""
							class="vertical-middle"
						/>
						<div class="asr-center-footer"></div>
						<div class="icon-img" v-if="isRecord">
							<img
								v-lazy="iconImd"
								:key="iconImd"
								alt="正在录音"
							/>
						</div>
						<el-button
							class="btn"
							type="primary"
							v-if="!isRecord"
							@click="hangRecord"
							>开始讲话</el-button
						>
						<el-button
							class="btn"
							type="primary"
							style="width: 1.6rem"
							v-else
							@click="stopRecord"
							>停止录音并开始识别</el-button
						>
					</div>
				</div>
				<div class="vs">
					<div class="vs-title">语音合成</div>
					<div class="vs-center">
						<textarea
							placeholder="请输入需要合成的文字内容"
							class="vs-center-box"
							v-model="textValue"
						></textarea>
						<img
							v-if="vsLoading"
							v-lazy="loading1Img"
							:key="loading1Img"
							alt=""
							class="vertical-middle"
						/>
						<!-- <el-input
              type="textarea"
              placeholder="请输入需要合成的文字内容。"
              v-model="textarea"
              class="vs-center-box"
            ></el-input> -->
						<div class="select">
							<el-select
								v-model="languageValue"
								placeholder="中文"
								@change="langChange"
							>
								<el-option
									v-for="item in language"
									:key="item.id"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
							<el-select
								v-model="voiceValue"
								collapse-tags
								style="margin-left: 0.2rem"
								placeholder="女声"
								@change="sexChange"
							>
								<el-option
									v-for="item in voiceSex"
									:key="item.id"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
						</div>
						<el-button
							class="btn"
							type="primary"
							@click="compound"
							:disabled="!textValue"
							>合成播放</el-button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//vue实现录音功能js-audio-recorder带波浪图 地址：https://blog.csdn.net/qq_41619796/article/details/107865602
import Recorder from 'js-audio-recorder'
const recorder = new Recorder({
	sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
	sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
	numChannels: 1, // 声道，支持 1 或 2， 默认是1
	// compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
})
export default {
	name: 'asrAndVs',
	data() {
		return {
			isRecord: false,
			asrLoading: false,
			vsLoading: false,
			asrTextContent: '',
			iconImd: window.imgUrl + '/record.gif',
			loading1Img: window.imgUrl + '/loading1.gif',
			textValue: '',
			language: [
				{
					id: 0,
					value: '0',
					label: '中英',
				},
				{
					id: 1,
					value: '1',
					label: '粤语',
				},
				{
					id: 2,
					value: '2',
					label: '四川话',
				},
			],
			languageValue: '',
			voiceSex: [
				{
					id: 0,
					value: '0',
					label: '女声',
				},
				{
					id: 1,
					value: '1',
					label: '男声',
				},
			],
			voiceValue: '',
		}
	},
	watch: {},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
		//PC端录制语音
		getPermission() {
			Recorder.getPermission().then(
				() => {
					console.log('获取权限成功')
				},
				(error) => {
					console.log(`${error.name} : ${error.message}`)
				}
			)
		},
		//开始录音
		startRecorder() {
			recorder.start().then(
				() => {},
				(error) => {
					console.log(`${error.name} : ${error.message}`)
				}
			)
		},
		hangRecord() {
			this.isRecord = !this.isRecord
			this.startRecorder()
		},
		// 结束录音
		stopRecorder() {
			recorder.stop()
		},
		// 点击结束按钮
		stopRecord() {
			this.isRecord = false
			this.asrLoading = true
			recorder.stop()

			let blob = recorder.getWAVBlob()
			//将获取的二进制文件转为二进制流
			const file = new File([blob], 'audio.wav')
			let formData = new FormData()
			formData.append('file', file)
			this.axios
				.request({
					url:
						'https://jet-voice.com/aliyun-jetcaswechat/' +
						'/robotXiaoAi/voice',
					method: 'post',
					data: formData,
				})
				.then((res) => {
					if (res.data.code === 200) {
						// recorder.downloadWAV("新文件");//下载音频
						this.asrTextContent = res.data.data.content
						let textHtml =
							document.querySelector('.asr-center-text')
						textHtml.innerHTML = this.asrTextContent
						textHtml.style.color = '#000'
						this.asrLoading = false
						// recorder.play();//播放音频
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},
		/*
    分割线===============================================================
    ==========================语音合成===================================
    */
		compound() {
			//合成播放
			this.vsLoading = true
			var capKey = null
			if (this.languageValue == 0 && this.voiceValue == 0) {
				capKey = 0
			} else if (this.languageValue == 0 && this.voiceValue == 1) {
				capKey = 1
			} else if (this.languageValue == 1) {
				capKey = 2
			} else if (this.languageValue == 2) {
				capKey = 3
			}
			const params = new FormData()
			params.append('capkey', capKey)
			params.append('text', this.textValue)
			this.axios
				.request({
					url:
						'https://jet-voice.com/aliyun-jetcaswechat/' +
						'/wxreco/major',
					method: 'post',
					data: params,
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.vsLoading = false
						const audio = new Audio()
						audio.src =
							'https://jet-voice.com/aliyun-jetcaswechat/' +
							res.data.results
						audio.play()
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},
		langChange() {
			//点击选择语言
			if (this.languageValue == 1 || this.languageValue == 2) {
				//选择粤语和四川话，只有女生的声音
				this.voiceSex = [
					{
						id: 0,
						value: '0',
						label: '女声',
					},
				]
			} else {
				this.voiceSex = [
					{
						id: 0,
						value: '0',
						label: '女声',
					},
					{
						id: 1,
						value: '1',
						label: '男声',
					},
				]
			}
		},
		sexChange() {
			//点击选择性别
			if (this.voiceValue == 1) {
				//选择男声，只有中英混合的语言
				this.language = [
					{
						id: 0,
						value: '0',
						label: '中英',
					},
				]
			} else {
				this.language = [
					{
						id: 0,
						value: '0',
						label: '中英',
					},
					{
						id: 1,
						value: '1',
						label: '粤语',
					},
					{
						id: 2,
						value: '2',
						label: '四川话',
					},
				]
			}
		},
	},
	components: {},
	computed: {},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	mounted() {
		this.languageValue = this.language[0].value
		this.voiceValue = this.voiceSex[0].value
		// 初始化开启录音权限
		this.getPermission()
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.asrAndVs {
	.content {
		width: 100%;
		// min-height: 800px;
		// margin-bottom: 60px;
		.center {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		}
		.asr {
			width: 50%;
			.asr-title {
				font-size: 0.28rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #127bf2;
				line-height: 0.36rem;
				margin-bottom: 0.2rem;
			}
			.asr-center {
				width: 100%;
				height: 6.4rem;
				background-color: #f8fafd;
				border-radius: 0.1rem 0 0 0.1rem;
				position: relative;
				padding: 0.4rem;
				box-sizing: border-box;
				p {
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #acb7c4;
					line-height: 0.28rem;
				}
				.vertical-middle {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					width: 0.3rem;
					height: 0.3rem;
				}
				.icon-img {
					width: 0.6rem;
					height: 0.6rem;
					position: absolute;
					left: 45%;
					bottom: 0.1rem;
				}
				.btn {
					width: 1.2rem;
					height: 0.4rem;
					font-size: 0.14rem;
					position: absolute;
					right: 0.2rem;
					bottom: 0.2rem;
					background: #127bf2;
					border-radius: 0.05rem;
					border: none;
				}
			}
		}
		.vs {
			width: 50%;
			.vs-title {
				font-size: 0.28rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #127bf2;
				line-height: 0.36rem;
				margin-bottom: 0.2rem;
			}
			.vs-center {
				width: 100%;
				height: 6.4rem;
				position: relative;
				.vs-center-box {
					width: 100%;
					height: 100%;
					font-size: 0.2rem;
					border: 0;
					padding: 0.4rem 0.4rem 0.8rem 0.4rem;
					box-sizing: border-box;
					border-radius: 0 0.1rem 0.1rem 0;
					background-color: #eceff3;
					resize: none;
					overflow-x: hidden;
					&:focus {
						outline: none !important;
						border: 1px solid #127bf2;
					}
				}
				.vertical-middle {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					width: 0.3rem;
					height: 0.3rem;
				}
				.select {
					position: absolute;
					left: 0.2rem;
					bottom: 0.2rem;
					.el-select {
						width: 1.2rem;
						height: 0.4rem;
						::v-deep .el-input__inner {
							height: 0.4rem;
						}
					}
				}
				.btn {
					width: 1.2rem;
					height: 0.4rem;
					font-size: 0.14rem;
					position: absolute;
					right: 0.2rem;
					bottom: 0.2rem;
					background: #127bf2;
					border-radius: 0.05rem;
					border: none;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.asrAndVs .content {
		.asr {
			width: 100%;
			margin-bottom: 40px;
			.asr-center {
				border-radius: 10px;
			}
		}
		.vs {
			width: 100%;
			margin-bottom: 40px;
			.vs-center .vs-center-box {
				border-radius: 10px;
			}
		}
	}
}
</style>
